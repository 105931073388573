.topnavbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #eeeeee;
    height: 56px;
}
.topnavbar_toggle-button {
  display:inline-flex;
}
.topnavbar_module {
    margin-left: 3rem;
}
.topnavbar_module a {
    color: black;
    text-decoration: none;
    font-size: 1.4rem;
}
.topnavbar_nav{
  margin-top: .5rem;
  display:flex;
}
.spacer{
  flex:1;
}
  .topnavbar_nav-items > ul {
    float:right;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .topnavbar_nav-items li {
    padding: 0 0.5rem;
  }
  
  .topnavbar_nav-items a {
    color: #521751;
  }
  
  .topnavbar_nav-items a:hover,
  .topnavbar_nav-items a:active {
    color: #fa923f;
  }
  
  
  @media (min-width: 769px) {
    .topnavbar_toggle-button {
      display: none;
    }
  
    .topnavbar_module {
      margin-left: 12rem;
    }
  }
  #profile-menu{
    top: 30px;
    left: 0px;
    width: 14rem;
  }
  .what-new-badge{
    margin-left:1em;
  }
  .search-icon{
  border-radius: '30px';
  padding : '12px';
  font-size: 18;
  background-color: 'white';
  -webkit-box-shadow: "1px 3px 1px #9E9E9E";

  }
