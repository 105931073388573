@import "../node_modules/normalize.css/normalize.css";

.country-flag img {
    height: 20px;
    width: 20px;
}

.country-label {
    padding: "10px"
}
.main-container{
    width:auto;
}

@media (min-width: 960px){

    .main-container{
        width:auto;
    }
        
}
@media (max-width: 960px){

    .main-container{
       margin:auto !important;
    }
    
    #react-module-title > p{
        font-size: 20px;
    }
        
}
html{
    font-size: unset;
    -webkit-box-sizing: unset  !important;
    -moz-box-sizing: unset  !important;
      box-sizing: unset !important;
}

.modal-body nav{
    display: none;
}
#react-module-title > p{
    font-size: 22px;
}


.MuiInputBase-input.Mui-disabled {
 color: gray;
}

.MuiFormLabel-root.Mui-disabled {
    color: gray !important;
}

.searchMuiFormLabel-root.Mui-disabled {
    color: gray !important;
}

.circleButton > .MuiButton-label > .MuiSvgIcon-root {
    margin-right: 0px !important;
}

.headerSearch .MuiExpansionPanelSummary-content {
    margin: 0px !important;
}

.MuiExpansionPanelSummary-root.Mui-focused  {
    background: white !important;
}

.MuiButton-label {
    word-break: break-all;
}

/* appear - on page load */
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}

.BottomBarName {
    background: 'none';
    color: 'white';
    display: 'inline-flex';
    margin-top:'-5px';
    max-width: '60%';
    min-width:'30%';
}

.SwipeViewsContainer {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    /*margin-top: 50px;
    margin-bottom: 30px*/;
  }
  
  .SwipeViewsHeader {
    min-height: 40px;
    border-bottom: #F0F0F0 4px solid;
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    top: 50px;
    z-index: 90;
    width: 100%;
  }
  
  .SwipeViewsTabs {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: -4px;
  }
  
  .SwipeViewsTabs > ul {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    padding: 0;
  }
  
  .SwipeViewsTab {
    display: inline;
    padding-top: 15px;
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
  
  .SwipeViewsTab > a {
    text-decoration: none;
    color: black;
    width: 100%;
    height: 100%;
    display: block;
  }
  
  .SwipeViewsTabs .active {
    color: #FF6950;
  }
  
  .SwipeViewsInk {
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    height: 4px;
    background-color: #FF6950;
    border-radius: 5px;
  }
  
  .SwipeViews {
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: hidden;
  }
  
  .SwipeView {
    overflow-y: auto;
  }

  .content-area {
    padding: 0 1em;
  }

  .quickSearchBox{
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  }
  #select-multiple-native{
    min-height:300px
  }
  
 /* .MuiOutlinedInput-notchedOutline {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
 }

 .MuiOutlinedInput-multiline {
  padding: 18.5px 0px 6px !important;
 }

 .MuiInputLabel-outlined.MuiInputLabel-shrink {
   transform: translate(0px, -6px) scale(0.75) !important;
 } */

 .MuiSelect-select:focus {
   background-color: rgb(0,0,0,0) !important;

 }

 .MuiSelect-outlined.MuiSelect-outlined {
  padding-top: 17px;
  padding-bottom: 8px;
  padding-left: 0px;
 }

 .rct-node.rct-node-leaf {
   margin-top: 25px !important;
 }

 .rct-node.rct-node-parent.rct-node-expanded {
    margin-top: 25px !important;
 }

 .rct-node.rct-node-parent.rct-node-collapsed {
    margin-top: 25px !important;
 }

 .rct-checkbox {
   font-size: 18px;
 }

 .rct-node-icon {
  font-size: 18px;
}

.rct-title {
  font-size: 18px;
}


.preview-card-icon-open {
  display: block;
  position: absolute;
  left: 1px;
  bottom: 1px;
  top: 1px;
  right: 1px;
  margin: auto;
  font-size: 30px;
  width: 90%;
  color: #f16d30;
  cursor: pointer;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}
.preview-card-icon-open:focus,
.preview-card-icon-open:hover {
  color: #111;
}
.preview-close-modal {
  position: fixed;
  top: 10px;
  right: 10px;
}
.preview-fullscreen {
  position: relative;
  text-decoration: none;
  font-size: 25px;
  color: #eee;
  z-index: 999;
}
.preview-fullscreen:hover,
.preview-fullscreen:focus,
.preview-fullscreen:blur {
  text-decoration: none;
  color: red;
}
.preview-container {
  padding-top: 10px;
}
.preview-card {
  position: relative;
  overflow: hidden;
  max-width: 300px;
  max-height: 160px;
  vertical-align: middle;
  display: inline-block;
}
.preview-thumbnail {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
}
.preview-thumbnail:focus ~ .preview-card-icon-open,
.preview-thumbnail:hover ~ .preview-card-icon-open,
.preview-thumbnail ~ .preview-card-icon-open:focus,
.preview-thumbnail ~ .preview-card-icon-open:hover {
  opacity: 1;
}
.preview-image-container {
  padding:10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-image-rotate {
  font-size: 44px;
}
.preview-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, 0.75);
}
.preview-modal-body {
  position: relative;
  top: 15%;
  z-index: 11;
  padding: 0;
  overflow: hidden;
  height: inherit;
}
.preview-modal-close {
  font-size: 40px;
  z-index: 99;
  color: #eee;
  transition: all 0.25s ease-in-out;
}
.preview-modal-close:focus,
.preview-modal-close:hover {
  color: red;
}
.preview-modal-close:focus,
.preview-modal-close:hover {
  color: red;
}
.preview-rotate-icon{
  color: rgb(238, 238, 238)
}
.preview-rotate-icon:hover,
.preview-rotate-icon:focus {
  color: #f16d30;
  
}
.preview-rotate-container {
  font-size: 40px;
  position: fixed;
  left: 1px;
  margin: auto;
  width: 1%;
  right: 1px;
  top: 60px;
  z-index: 999;

}
.preview-image-container img {
margin: auto;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    transform: rotate(0deg);
    width: 60%;
    height: 60%;
    position: fixed;
    object-fit: scale-down;
}