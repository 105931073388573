.side-nav-bar{
  background:#333333;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  transform: translateX(-100%);
  color: #D0C8C8;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  height: 100%;
  width: 200px;
  max-width: 212px;
  overflow-y: auto;
  overflow-x: hidden;
}
      
.side-nav-bar.open {
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
    transform: translateX(0);
}
  
.side-nav-bar ul {
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  .side-nav-bar ul > div{
    
    min-height: 44px;
  }
  .side-nav-bar-icon {
    padding: 1px;
    color: #D0C8C8 ;
  }
  .side-nav-bar-icon.arrow {
    padding: 1px;
    color: #D0C8C8 ;
  }
  
  @media (min-width: 768px) {
    .side-nav-bar-list:hover .side-nav-bar-icon.arrow{
        margin-right:-5px;
    }
  }
  .side-nav-bar-list{
    height: 70%;
    width: 208px;
    max-width: 212px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #333333;
    z-index: 300;
    transform: translateX(0);
    color: #D0C8C8;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    max-width: 212px;
  }
  .side-nav-minimized{
  max-width:57px;
  } 
  .side-nav-minimize-btn {
    background: #333333;
    bottom: 0;
    width: -webkit-fill-available;
    position: fixed;
    bottom: 0;
    border-top: 2px outset rgba(0, 0, 0, 0.19)
  }
  .side-nav-minimized span{
    color:transparent !important;
    }
 
  .side-nav-bar li {
    
    margin: 0.5rem 0;
  }
  .side-nav-bar li > *{
    color: #D0C8C8;
  }
  .side-nav-bar li a {
    color: #D0C8C8;
    text-decoration: none;
  }  
  .side-nav-bar li span {
    color: #D0C8C8;
    text-decoration: none;
  }
  .side-nav-bar li:hover,
  .side-nav-bar li a:active {
    color: #fa923f;
  }
  .side-nav-bar li > li {
    color: #D0C8C8;
    text-indent: 1rem;
    text-decoration: none;
  }
  @media (min-width: 960px) {
    .side-nav-bar {
          display: block;
          transform: translateX(0);
      }
  }

  .side-nav-bar-list-parent span{
  margin-left:-1rem;
  }

  nav::-webkit-scrollbar {
    width: 0px;
   
}
.side-nav-bar-list:hover::-webkit-scrollbar {
  width: 5px;
 
}
.side-nav-bar-list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(117, 117, 117, 0.33);
  outline: 1px solid #333333;
}
.side-nav-bar-list::-webkit-scrollbar {
  width:0px
}
.side-nav-bar-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin-top: 20px;
  background-color:rgba(51, 51, 51, 0.2);
}
nav::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color:rgba(42, 18, 1, 0.938);
  outline: 1px solid #333333;
}

.side-nav-bar-toggle {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 3px;
  box-sizing: border-box;
  margin-left:10px;
  margin-top: 7px;
}
.side-nav-bar-toggle:focus{
  outline: none;

}
.side-nav-bar-toggle__line{
  width: 30px;
  height: 2px;
  background:black;

}